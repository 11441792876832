import React,{useState,useEffect} from 'react';
import Functions from '../helpers/Functions';
import queryString       from 'query-string';
import { MDBDataTableV5 } from 'mdbreact';

const  queryStringParams = queryString.parse(window.location.search);

const thead = [
  {
    label:"#",
    field:"consecutivo",
  },
  {
    label:"Grupo",
    field:"grupo_entrenamiento",
  },
  {
    label:"Alumno",
    field:"nombres",
  },
  {
    label:"Posición de juego",
    field:"posicion_juego",
  },
  {
    label:"Nro Documento",
    field:"nro_documento",
  },
  {
    label:"Fecha incripción",
    field:"fecha",
  },
  {
    label:"Foto",
    field:"foto",
  },
  {
    label:"ID",
    field:"foto_cedula",
  },
  {
    label:"EPS",
    field:"foto_eps",
  },
  {
    label:"CM",
    field:"foto_certificado_medico",
  },
  {
    label:"CE",
    field:"foto_certificado_estudio",
  },
  {
    label:"Pagos",
    field:"pagos",
  },
]

export default function TopSearch() {
  const callbackInit=(data)=>{
    if (data.response.data) {
      setDatatable({
          columns: thead,
          rows: data.response.data
      })
    }
  }

  useEffect(()=>{
    let data = {
      token:queryStringParams.token,
    }
    Functions.PostAsync("Puente","gruposProfesoresDocumentosAlumnos",data,{},{name:"callbackInit",funct:callbackInit})
  },[])
  
  const [datatable, setDatatable] = useState({
    columns: thead,
    rows: [],
  });

  return <MDBDataTableV5 striped bordered hover borderless={true} order={['consecutivo', 'asc']} entriesOptions={[5, 15, 25]} entries={15} pagesAmount={4} data={datatable} searchTop searchBottom={false} />;
}